<template>
  <div>
    <div class="p-banner mb-40">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner1.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">我们的产品</div>
        <div class="p-banner-sub-title">我们的产品</div>
      </div>
    </div>
    <div class="container">
      <div class="product-item-title" v-animate-on-intersect>单元技术与设备</div>
      <el-row :gutter="50" class="flex-warp" justify="space-between" type="flex" v-animate-on-intersect>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item" @click="$goPage('/product/page-rrjj')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-1.png"></div>
            <div class="product-item-name">熔融结晶</div>
            <div class="product-item-desc">高纯化学品生产优选方案</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item" @click="$goPage('/product/page-fzzl')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-2.png"></div>
            <div class="product-item-name">分子蒸馏</div>
            <div class="product-item-desc">高沸点热敏物料分离利器</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item" @click="$goPage('/product/page-shjj')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-3.png"></div>
            <div class="product-item-name">升华结晶</div>
            <div class="product-item-desc">特殊的高纯分离解决方案</div>
          </div>
        </el-col>
      </el-row>
      <div class="product-item-title" v-animate-on-intersect>工艺技术</div>
      <el-row :gutter="50" class="flex-warp" justify="space-between" type="flex" v-animate-on-intersect>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item"  @click="$goPage('/product/page-ldctjj')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-4.png"></div>
            <div class="product-item-name">锂电池添加剂</div>
            <div class="product-item-desc">行业领先的成熟可靠技术</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item"  @click="$goPage('/product/page-qqsysw')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-5.png"></div>
            <div class="product-item-name">氢氰酸衍生物</div>
            <div class="product-item-desc">成熟产品的新技术</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="24">
          <div class="product-item"  @click="$goPage('/product/page-sfhfczyhly')">
            <div class="product-item-img"><img alt="" src="../../assets/img/product/item1-6.png"></div>
            <div class="product-item-name">三废资源化技术</div>
            <div class="product-item-desc">困难问题的解决新方案</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
